export enum ROLES {
    admin = "admin",
    hotel = "hotel",
    provincia = "provincia",
    consorzio = "consorzio",
    comune = "comune",
    hotel_delegated = "hotel_delegated",
    application = "application",
}

/*
    const PRIVATO = 1;
    const AZIENDA = 2;
    const DESTINAZIONE = 3;
    const PROVINCIA = 4;
    const COMUNE = 5;
    const ADMIN = 6;
    const MANDAMENTO = 7;
 */
export enum MANAGER_TYPES {
    privato = 1,
    azienda = 2,
    destinazione = 3,
    mandamento = 7,
    provincia = 4,
    comune = 5,
    admin = 6,
}

export enum USER_GRANTS {
    RIAPERTURA_MESE = "riapertura-mese",
    CREA_NUOVA_STRUTTURA = "crea-nuova-struttura",
    SBLOCCO_OSPITI = "sblocco-ospiti",
    GRANDE_FRATELLO = "grande-fratello",
    CHIUSURA_MASSIVA = "chiusura-massiva",
    CANCELLAZIONE_FILE_UPLOAD = "cancellazione-file-upload",
    IMPERSONA = "impersona",
    INSERIMENTO_RAPIDO_C59 = "inserimento-rapido-c59",
    CREAZIONE_NUOVA_STRUTTURA = "creazione-nuova-struttura",
    ACCESSO_OSPITI_PROVINCIA = "gestione-ospiti",
    CIR_PROVINCIA = "cir-provincia",
    STATISTICHE = "statistiche",
    FILE_UPLOAD_ISTAT = "file-upload-istat",
    MODIFICA_SCHEDA_PROMOZIONALE = "modifica-scheda-promozionale",
    DELEGHE_UTENTI = "deleghe-utenti",
    TASSA_SOGGIORNO = "tassa-soggiorno",
    ACCESSO_PRATICHE_HOTEL = "accesso-pratiche-hotel",
    GESTIONE_PRATICHE_ATTIVITA_RICETTIVE = "gestione-accommodation-info-license",
}

export const MANAGER_TYPES_LABEL = {
    [MANAGER_TYPES.azienda]: "Azienda",
    [MANAGER_TYPES.privato]: "Privato",
    [MANAGER_TYPES.destinazione]: "Ufficio Turistico",
    [MANAGER_TYPES.mandamento]: "Consorzio Turistico o ATP",
    [MANAGER_TYPES.provincia]: "Amministrazione Provinciale",
    [MANAGER_TYPES.comune]: "Amministrazione Comunale",
    [MANAGER_TYPES.admin]: "Super admin",
};

export const MANAGER_TYPES_OPTIONS = [
    { id: MANAGER_TYPES.azienda, name: "Azienda" },
    { id: MANAGER_TYPES.privato, name: "Privato" },
    { id: MANAGER_TYPES.destinazione, name: "Ufficio Turistico" },
    { id: MANAGER_TYPES.mandamento, name: "Consorzio Turistico o ATP" },
    { id: MANAGER_TYPES.provincia, name: "Amministrazione Provinciale" },
    { id: MANAGER_TYPES.comune, name: "Amministrazione Comunale" },
    { id: MANAGER_TYPES.admin, name: "Super admin" },
];

export const MANAGER_TYPES_OPTIONS_HOTEL = [
    { id: MANAGER_TYPES.azienda, name: "Azienda" },
    { id: MANAGER_TYPES.privato, name: "Privato" },
];
export const MANAGER_TYPES_OPTIONS_ENTI = [
    { id: MANAGER_TYPES.destinazione, name: "Ufficio Turistico" },
    { id: MANAGER_TYPES.mandamento, name: "Consorzio Turistico o ATP" },
    { id: MANAGER_TYPES.comune, name: "Amministrazione Comunale" },
];

export const MANAGER_TYPES_OPTIONS_ENTI_ADMIN = [
    { id: MANAGER_TYPES.destinazione, name: "Ufficio Turistico" },
    { id: MANAGER_TYPES.mandamento, name: "Consorzio Turistico o ATP" },
    { id: MANAGER_TYPES.provincia, name: "Amministrazione Provinciale" },
    { id: MANAGER_TYPES.comune, name: "Amministrazione Comunale" },
    { id: MANAGER_TYPES.admin, name: "Super admin" },
];
export const MANAGER_TYPES_BASE_ROUTE = {
    [MANAGER_TYPES.azienda]: "hotel",
    [MANAGER_TYPES.privato]: "hotel",
    [MANAGER_TYPES.destinazione]: "consorzio",
    [MANAGER_TYPES.mandamento]: "consorzio",
    [MANAGER_TYPES.provincia]: "provincia",
    [MANAGER_TYPES.comune]: "comune",
    [MANAGER_TYPES.admin]: "admin",
};
export enum CITY_TAX_SETUP_ATTACHMENT_TAGS {
    regolamento = "regolamento_comunale",
    modulo_rifiuto = "modulo_rifiuto",
    modulo_esenzione_autista = "modulo_esenzione_autista",
}

export const PAYMENT_PAGOPA = "pagopa"; 
export const PAYMENT_BANK_TRANSFER = "bank_transfer";
export const PAYMENT_CASH = "cash";

export const PAYMENTS_TYPES = {
    [PAYMENT_PAGOPA]: "PagoPA",
    [PAYMENT_BANK_TRANSFER]: "Bonifico",
    [PAYMENT_CASH]: "Contanti",
};

export const PAYMENT_PENDING = "pending";
export const PAYMENT_PAID = "paid";
export const PAYMENT_CANCELLED = "cancelled";

export const PAYMENTS_STATUS = {
    [PAYMENT_PENDING]: "In Attesa",
    [PAYMENT_PAID]: "Pagato",
    [PAYMENT_CANCELLED]: "Annullato",
};
