<template>
    <el-button
        :type="type"
        @click="handleClick"
        v-bind="$props"
        :class="[$props.class, !label && 'abit-no-label-button']"
        :icon="!iconRight && icon ? icon : null"
    >
        {{ label }}
        <slot name="default" />
        <slot name="icon" v-if="iconRight && icon">
            <el-icon class="el-icon--right"
                ><component v-bind:is="icon"></component>
            </el-icon>
        </slot>
    </el-button>
</template>

<script>

export default {
    name: "Button",
    props: {
        label: {
            type: String,
        },
        href: {
            type: String,
            default: null,
        },
        target: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: "primary",
        },
        icon: { type: [String, Object, Function] },
        iconRight: { type: Boolean, default: () => false },
    },
    methods: {
        handleClick() {
            if (this.href) {
                window.open(this.href, this.target || '_blank');
            }
        },
    },
};
</script>

<style>
.el-button {
    margin-bottom: 1.25em;
}
.el-button .custom-loading .circular {
    margin-right: 6px;
    width: 18px;
    height: 18px;
    animation: loading-rotate 2s linear infinite;
}
.el-button .custom-loading .circular .path {
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: var(--el-button-text-color);
    stroke-linecap: round;
}
.el-button.icon-left > span {
    display: flex;
    direction: rtl;
    align-items: baseline;
}
.abit-no-label-button.el-button [class*="el-icon"] + span {
    margin-left: 0 !important;
}
</style>
