import { MANAGER_TYPES, ROLES, USER_GRANTS } from "@/core/config/AppConfig";

export default {
    path: "hotel/:accommodationId?/",
    component: () => import("@/layout/RouterViewWrapper.vue"),
    name: "hotel",
    meta: {
        guards: {
            types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
            roles: [ROLES.hotel, ROLES.hotel_delegated, ROLES.application],
        },
    },
    children: [
        {
            path: "",
            name: "hotel.dashboard",
            component: () => import("@/views/DashboardHotel.vue"),
        },
        {
            path: "api_test_guest",
            name: "hotel.api_test",
            component: () => import("@/views/ApiGuestTest.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.application],
                },
            },
        },
        {
            path: "api_test_calendar",
            name: "hotel.api_test_calendar",
            component: () => import("@/views/ApiCalendarTest.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.application],
                },
            },
        },
        {
            path: "users",
            name: "hotel.users",
            component: () => import("@/views/users/hotel/HotelUsersList.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel],
                },
                meta: {
                    guards: {
                        types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                        roles: [ROLES.hotel, ROLES.hotel_delegated],
                    },
                },
            },
            children: [
                {
                    path: "add",
                    name: "hotel.users.associate",
                    component: () =>
                        import("@/views/users/hotel/UserDelegateModal.vue"),
                },
            ],
        },

        {
            path: "citytax",
            name: "hotel.citytax",
            component: () => import("@/views/citytax/CitytaxSummaryHotel.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                    grant: USER_GRANTS.TASSA_SOGGIORNO,
                },
            },
        },

        {
            path: "citytax/payments/detail/:paymentId",
            name: "hotel.citytax.detail",
            component: () => import("@/views/citytax/CitytaxPaymentDetail.vue"),
            meta: {
                guards: {
                    roles: [
                        ROLES.hotel,
                        {
                            role: ROLES.hotel_delegated,
                            grant: USER_GRANTS.TASSA_SOGGIORNO,
                        },
                    ],
                },
            },
        },
        {
            path: "rates",
            name: "hotel.rates",
            component: () => import("@/views/rates/hotel/RateForm.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                },
            },
        },
        {
            path: "istat",
            name: "hotel.istat.guests",
            component: () => import("@/views/istat/GuestManager.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato, MANAGER_TYPES.mandamento,MANAGER_TYPES.destinazione],
                    roles: [ROLES.hotel, ROLES.hotel_delegated, ROLES.consorzio],
                },
            },
            children: [
                {
                    path: "departures/:licenseId/:parentId/:year/:month",
                    name: "hotel.istat.guests.departures",
                    component: () =>
                        import("@/views/istat/GuestsDeparturesModal.vue"),
                },
                {
                    path: "edit/:licenseId/:parentId/:year/:month",
                    name: "hotel.istat.guests.edit",
                    component: () =>
                        import("@/views/istat/GuestsEditModal.vue"),
                },
            ],
        },
        {
            path: "istat/totals",
            name: "hotel.istat.monthly-totals",
            component: () => import("@/views/istat/MonthlyTotalsList.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                },
            },
        },
        {
            path: "istat/c59",
            name: "hotel.istat.istat-c59",
            component: () => import("@/views/istat/C59.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                    grant: USER_GRANTS.INSERIMENTO_RAPIDO_C59,
                },
            },
        },
        {
            path: "istat/uploads",
            name: "hotel.istat.fileuploads",
            component: () => import("@/views/istat/FileuploadList.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                },
            },
        },
        {
            path: "istat/closing-calendar",
            name: "hotel.istat.closing-calendar",
            component: () => import("@/views/istat/ClosingCalendarList.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                },
            },
        },
        {
            path: "istat/integration-settings",
            name: "hotel.istat.integration-settings",
            component: () =>
                import("@/views/istat/IstatIntegrationSettings.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                },
            },
        },
        /*      {
          path: "istat/closing_calendar",
          name: "hotel.closing",
          component: () => import("@/views/crafted/pages/profile/Overview.vue"),
        },*/
        {
            path: "info",
            name: "hotel.info.general",
            component: () =>
                import("@/views/accommodations/hotel/AccommodationInfo.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                },
            },
        },
        {
            path: "info/licences",
            name: "hotel.info.licence",
            component: () =>
                import(
                    "@/views/accommodations/hotel/AccommodationLicences.vue"
                ),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel],
                },
            },
        },
        {
            path: "info/marketing",
            name: "hotel.info.marketing",
            component: () =>
                import(
                    "@/views/accommodations/hotel/AccommodationMarketing.vue"
                ),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                },
            },
        },
        {
            path: "info/images",
            name: "hotel.info.images",
            component: () =>
                import("@/views/accommodations/hotel/AccommodationImages.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                },
            },
        },
        {
            path: "analytics",
            name: "hotel.analytics",
            component: () =>
                import("@/views/crafted/pages/profile/Overview.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                },
            },
        },
        {
            path: "analytics/guest-nights",
            name: "hotel.analytics.guest-nights",
            component: () => import("@/views/analytics/HotelGuests.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                },
            },
        },
        {
            path: "analytics/origin",
            name: "hotel.analytics.origin",
            component: () => import("@/views/analytics/HotelOrigin.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                },
            },
        },
        {
            path: "analytics/citytax",
            name: "hotel.analytics.citytax",
            component: () => import("@/views/analytics/HotelCitytax.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [
                        ROLES.hotel,
                        {
                            role: ROLES.hotel_delegated,
                            grant: USER_GRANTS.TASSA_SOGGIORNO,
                        },
                    ],
                },
            },
        },
        {
            path: "analytics/studi-settore",
            name: "hotel.analytics.studi-settore",
            component: () => import("@/views/analytics/HotelStudiSettore.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                },
            },
        },
        {
            path: "analytics/modello-21",
            name: "hotel.analytics.modello-21",
            component: () => import("@/views/analytics/HotelModello21.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.azienda, MANAGER_TYPES.privato],
                    roles: [ROLES.hotel, ROLES.hotel_delegated],
                    grant: USER_GRANTS.TASSA_SOGGIORNO,
                },
            },
        },
    ],
};
